@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
 
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
 
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
 
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
 
    --accent: 240 60% 50%;
    --accent-foreground: 240 5.9% 10%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
 
    --radius: 0.5rem;
    --chart-1: 200 65% 45%;
    --chart-2: 150 55% 40%;
    --chart-3: 25 75% 55%;
    --chart-4: 340 65% 50%;
    --chart-5: 270 60% 50%;
    --chart-6: 180 55% 45%;
    --chart-7: 45 70% 50%;
    --chart-8: 315 50% 45%;
    --chart-9: 165 50% 40%;
    --chart-10: 10 65% 50%;
    --chart-11: 235 55% 45%;
    --chart-12: 135 45% 40%;
    --chart-13: 35 70% 50%;
    --chart-14: 290 55% 45%;
    --chart-15: 195 60% 45%;
    --chart-16: 55 65% 45%;
    --chart-17: 325 50% 45%;
    --chart-18: 175 50% 40%;
    --chart-19: 15 60% 45%;
    --chart-20: 255 55% 50%;
  }
 
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
 
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
 
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
 
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
 
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
 
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
 
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
 
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 200 70% 60%;
    --chart-2: 150 65% 55%;
    --chart-3: 25 80% 65%;
    --chart-4: 340 70% 65%;
    --chart-5: 270 65% 65%;
    --chart-6: 180 60% 60%;
    --chart-7: 45 75% 65%;
    --chart-8: 315 60% 60%;
    --chart-9: 165 55% 55%;
    --chart-10: 10 70% 65%;
    --chart-11: 235 65% 60%;
    --chart-12: 135 55% 55%;
    --chart-13: 35 75% 65%;
    --chart-14: 290 65% 60%;
    --chart-15: 195 70% 60%;
    --chart-16: 55 70% 60%;
    --chart-17: 325 65% 60%;
    --chart-18: 175 60% 55%;
    --chart-19: 15 70% 60%;
    --chart-20: 255 65% 65%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}